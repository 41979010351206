export enum SOLID_DECLINE_REASONS {
  GENERAL_DECLINE = 'General decline',
  ORDER_EXPIRED = 'Order expired',
  ILLEGAL_OPERATION = 'Illegal operation',
  AUTHENTICATION_FAILED = 'Authentication failed',
  INVALID_DATA_OR_ORDER_NOT_FOUND = 'Invalid Data/Order not found',
  INVALID_AMOUNT = 'Invalid Amount',
  INVALID_CURRENCY = 'Invalid Currency',
  ORDER_NOT_FOUND = 'Order not found',
  INVALID_CVV2_CODE = 'Invalid CVV2 code',
  REQUEST_IS_EMPTY = 'Request Is empty',
  INVALID_CARD_NUMBER = 'Invalid card number',
  INVALID_EXPIRATION_DATE = 'Invalid expiration date',
  INVALID_3DS_FLOW_MERCHANT_SIDE = 'Invalid 3DS flow on the merchant side',
  INVALID_3DS_FLOW_BANK_SIDE = 'Invalid 3DS flow on the bank side',
  INVALID_3DS_FLOW = 'Invalid 3DS flow',
  INVALID_IP = 'Invalid IP',
  SUBSCRIPTION_ERROR = 'Subscription Error',
  SCA_REQUIRE_3D_AUTHENTICATION = 'SCA require 3D authentication',
  SUBSCRIPTION_IS_LOCKED = 'Subscription is locked',
  CARD_IS_BLOCKED = 'Card is blocked',
  INSUFFICIENT_FUNDS = 'Insufficient funds',
  PAYMENT_AMOUNT_LIMIT_EXCESS = 'Payment amount limit excess',
  TRANSACTION_DECLINED_BY_ISSUER = 'The transaction is declined by the issuer',
  CALL_YOUR_BANK = 'Call your bank',
  DEBIT_CARD_NOT_SUPPORTED = 'Debit card not supported',
  CARD_BRAND_NOT_SUPPORTED = 'Card brand is not supported',
  DO_NOT_HONOR = 'Do not honor',
  SECURE_AUTHENTICATION_REQUIRED = '3D-Secure authentication required',
  SUSPECTED_FRAUD = 'Suspected Fraud',
  RECURRING_PAYMENT_CANCELLED = 'Recurring payment cancelled',
  CARD_IN_BLACKLIST = 'Card is in a black list',
  STOLEN_CARD = 'Stolen card',
  RESTRICTED_CARD = 'Restricted card',
  LOST_CARD = 'Lost card',
  PSP_ANTIFRAUD = 'PSP antifraud',
  BLOCKED_BY_COUNTRY_OR_IP = 'Blocked by Country/IP',
  TRUSTED_ANTIFRAUD_SYSTEM = 'Trusted antifraud system',
  AVS_MISMATCH = 'AVS mismatch',
  ANTIFRAUD_SERVICE = 'Antifraud service',
  SECURE_VERIFICATION_FAILED = '3D secure verification failed',
  INVALID_CARD_TOKEN = 'Invalid Card Token',
  APPLICATION_ERROR = 'Application error',
  MERCHANT_NOT_CONFIGURED_CORRECTLY = 'Merchant is not configured correctly',
  MERCHANT_NOT_ACTIVATED_YET = 'Merchant is not activated yet',
  DUPLICATE_ORDER = 'Duplicate order',
  EXCEEDED_API_CALLS_LIMITS = 'Exceeded API calls limits',
  INVALID_TRANSACTION = 'Invalid transaction',
  MERCHANT_NOT_FOUND = 'Merchant not found',
  PROCESSOR_DOES_NOT_SUPPORT_REQUESTED_API_METHOD = 'Processor does not support requested API method',
  INVALID_ROUTING = 'Invalid routing',
  ACCOUNT_IS_BLOCKED = 'Account is blocked',
  UNKNOWN_DECLINE_CODE = 'Unknown decline code',
  CONNECTION_ERROR = 'Connection error',
  CARD_TOKEN_NOT_FOUND = 'Card token not found',
  GOOGLE_PAYMENT_ERROR = 'Google payment error',
  SMART_CASCADE_DECLINE = 'Smart cascade decline',
  CASCADE_TO_2D = '3DS cascade to 2D',
  APPLE_ONLINE_PAYMENT_ERROR = 'Apple online payment error',
  TOKEN_GENERATION_ERROR = 'Token generation error',
  SCA_ENGINE_ERROR = 'SCA engine error',
  SOLID_ANTIFRAUD_ENGINE = 'Solid antifraud engine',
}

export enum COREFY_DECLINE_REASONS {
  ANTIFRAUD_ERROR = 'Antifraud error',
  AUTH_3DS_REQUIRED = '3DS authentication required',
  AUTH_OTP_REQUIRED = 'OTP authentication required',
  AUTH_FATAL_ERROR = 'Authentication fail',
  CARD_EXPIRED = 'Card expired',
  CARD_3DS_ENROLLED = 'Card 3DS enrolled',
  CARD_BLOCKED_BY_INVALID_PIN = 'Card blocked by invalid pin',
  CARD_LOST = 'Card lost',
  CARD_NOT_3DS_ENROLLED = 'Card not 3DS enrolled',
  CARD_STOLEN = 'Card stolen',
  CARD_RESTRICTED = 'Card restricted',
  CARD_RESTRICTED_BY_ACQUIRER = 'Card restricted by acquirer',
  INVALID_CARD_TYPE = 'Invalid card type',
  CARD_USAGE_LIMIT_VIOLATION = 'Card usage limit violation',
  CARD_USAGE_ISSUER_LIMIT_VIOLATION = 'Card usage issuer limit violation',
  CARD_VERIFICATION_FAILED = 'Card verification failed',
  CLIENT_AUTH_FAILED = 'Client authorization failed',
  CUSTOMER_BLOCKED = 'Customer blocked',
  GENERAL_DECLINE = 'General decline',
  TRANSACTION_EXPIRED = 'Transaction expired',
  FATAL_DECLINE = 'Fatal decline',
  FATAL_ERROR = 'Fatal error',
  FATAL_INVALID_CARD = 'Invalid card. Fatal error',
  FORCED_CAPTURED = 'Forced captured',
  INSUFFICIENT_FUNDS = 'Insufficient funds',
  INVALID_3DS_CODE = 'Invalid 3DS code',
  INVALID_AMOUNT = 'Invalid amount',
  INVALID_CARD = 'Invalid card',
  INVALID_CARDHOLDER = 'Invalid cardholder',
  INVALID_CVV = 'Invalid cvv',
  INVALID_DETAILS = 'Invalid details',
  INVALID_EXPIRE_DATE = 'Invalid expire date',
  INVALID_OTP_CODE = 'Invalid OTP code',
  INVALID_PAN = 'Invalid pan',
  ISSUER_DECLINE = 'Issuer decline',
  ISSUER_UNAVAILABLE = 'Issuer unavailable',
  LIMIT_VIOLATION = 'Limit violation',
  UNABLE_DETERMINE_3DS_ENROLMENT = 'Unable to determine 3DS enrolment',
  UNKNOWN = 'Unknown decline',
  FALLBACK_MESSAGE = 'An error occurred during payment', // when corefy returns no message
}

export enum COINSPAID_DECLINE_REASONS {
  GENERAL_DECLINE = 'Your crypto payment was declined. Please try again',
}

export const HIDDEN_DECLINE: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.SOLID_ANTIFRAUD_ENGINE,
  SOLID_DECLINE_REASONS.TRUSTED_ANTIFRAUD_SYSTEM,
  SOLID_DECLINE_REASONS.CARD_IN_BLACKLIST,
  SOLID_DECLINE_REASONS.LOST_CARD,
  SOLID_DECLINE_REASONS.BLOCKED_BY_COUNTRY_OR_IP,
  SOLID_DECLINE_REASONS.ANTIFRAUD_SERVICE,
  COREFY_DECLINE_REASONS.ANTIFRAUD_ERROR,
  COREFY_DECLINE_REASONS.CARD_STOLEN,
  COREFY_DECLINE_REASONS.CUSTOMER_BLOCKED,
  COREFY_DECLINE_REASONS.CARD_LOST,
  COREFY_DECLINE_REASONS.FATAL_DECLINE,
  COREFY_DECLINE_REASONS.FATAL_ERROR,
  COREFY_DECLINE_REASONS.FATAL_INVALID_CARD,
]

export const HIDDEN_DECLINE_NEEDS_MERCHANT_NAME: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.PSP_ANTIFRAUD,
  SOLID_DECLINE_REASONS.SUSPECTED_FRAUD,
  SOLID_DECLINE_REASONS.DO_NOT_HONOR,
]

export const GENERAL_DECLINE: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INVALID_CARD_TOKEN,
  SOLID_DECLINE_REASONS.INVALID_TRANSACTION,
  SOLID_DECLINE_REASONS.CARD_TOKEN_NOT_FOUND,
  COREFY_DECLINE_REASONS.UNKNOWN,
  COREFY_DECLINE_REASONS.FALLBACK_MESSAGE,
]

export const ISSUER_DECLINE: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.GENERAL_DECLINE,
  SOLID_DECLINE_REASONS.ILLEGAL_OPERATION,
  SOLID_DECLINE_REASONS.TRANSACTION_DECLINED_BY_ISSUER,
  SOLID_DECLINE_REASONS.CALL_YOUR_BANK,
  SOLID_DECLINE_REASONS.UNKNOWN_DECLINE_CODE,
  COREFY_DECLINE_REASONS.ISSUER_DECLINE,
]

export const LIMIT_VIOLATION: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.PAYMENT_AMOUNT_LIMIT_EXCESS,
  COREFY_DECLINE_REASONS.LIMIT_VIOLATION,
]

export const INSUFFICIENT_FUNDS: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INSUFFICIENT_FUNDS,
  COREFY_DECLINE_REASONS.INSUFFICIENT_FUNDS,
]

export const RESTRICTED_CARD: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.DEBIT_CARD_NOT_SUPPORTED,
  SOLID_DECLINE_REASONS.CARD_BRAND_NOT_SUPPORTED,
  SOLID_DECLINE_REASONS.RESTRICTED_CARD,
  SOLID_DECLINE_REASONS.APPLICATION_ERROR,
  COREFY_DECLINE_REASONS.CARD_RESTRICTED,
  COREFY_DECLINE_REASONS.CARD_RESTRICTED_BY_ACQUIRER,
]

export const SECURITY_CHECK: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INVALID_3DS_FLOW_BANK_SIDE,
  SOLID_DECLINE_REASONS.INVALID_3DS_FLOW_MERCHANT_SIDE,
  SOLID_DECLINE_REASONS.INVALID_3DS_FLOW,
  SOLID_DECLINE_REASONS.SCA_REQUIRE_3D_AUTHENTICATION,
  SOLID_DECLINE_REASONS.SECURE_VERIFICATION_FAILED,
  COREFY_DECLINE_REASONS.CLIENT_AUTH_FAILED,
  COREFY_DECLINE_REASONS.AUTH_3DS_REQUIRED,
  COREFY_DECLINE_REASONS.AUTH_OTP_REQUIRED,
  COREFY_DECLINE_REASONS.CARD_NOT_3DS_ENROLLED,
  COREFY_DECLINE_REASONS.INVALID_3DS_CODE,
  COREFY_DECLINE_REASONS.INVALID_OTP_CODE,
  COREFY_DECLINE_REASONS.UNABLE_DETERMINE_3DS_ENROLMENT,
  COREFY_DECLINE_REASONS.AUTH_FATAL_ERROR,
]

export const BLOCKED_CARD: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.CARD_IS_BLOCKED,
  SOLID_DECLINE_REASONS.STOLEN_CARD,
  COREFY_DECLINE_REASONS.CARD_BLOCKED_BY_INVALID_PIN,
]

export const TIME_EXPIRED: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.ORDER_EXPIRED,
  COREFY_DECLINE_REASONS.TRANSACTION_EXPIRED,
]

export const INVALID_EXPIRY_DATE: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INVALID_EXPIRATION_DATE,
  COREFY_DECLINE_REASONS.INVALID_EXPIRE_DATE,
]

export const INVALID_CVV_CODE: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INVALID_CVV2_CODE,
  COREFY_DECLINE_REASONS.INVALID_CVV,
]

export const CARD_EXPIRED: AllDeclineReasons[] = [
  COREFY_DECLINE_REASONS.CARD_EXPIRED,
]

export const INVALID_DATA: AllDeclineReasons[] = [
  SOLID_DECLINE_REASONS.INVALID_DATA_OR_ORDER_NOT_FOUND,
  SOLID_DECLINE_REASONS.INVALID_CARD_NUMBER,
  COREFY_DECLINE_REASONS.INVALID_CARD,
  COREFY_DECLINE_REASONS.INVALID_CARD_TYPE,
  COREFY_DECLINE_REASONS.INVALID_DETAILS,
]

export const INVALID_CARDHOLDER: AllDeclineReasons[] = [
  COREFY_DECLINE_REASONS.INVALID_CARDHOLDER,
]

export enum DeclineStatusComponents {
  ACQUIRER_SIDE_PROBLEM = 'AcquirerSideProblem',
  TRANSACTION_PROBLEM = 'TransactionProblem',
  WRONG_DATA_OR_TIME = 'WrongTimeOrData',
  HIDDEN_REASON = 'HiddenReason',
  CRYPTO_DECLINE = 'CryptoDecline',
}

export type AllDeclineReasons =
  | SOLID_DECLINE_REASONS
  | COREFY_DECLINE_REASONS
  | COINSPAID_DECLINE_REASONS
