import ErrorTracking from '@socialtechnologies/error-tracking-lib'

import { LayoutTemplate } from '@/types/config/layout'

type OrderData = {
  hash: string
  template?: LayoutTemplate
}

export const getOrderFromUrl = (): OrderData | null => {
  const { href } = window.location
  const url = new URL(href)
  const encodedOrderData = url.searchParams.get('orderData') || ''

  if (encodedOrderData) {
    try {
      const decodedData = window.atob(encodedOrderData)
      const orderData: OrderData = JSON.parse(decodedData)

      return orderData
    } catch (error) {
      ErrorTracking.captureMessage('Failed to decode or parse order data from the URL', {
        extra: error,
      })

      return null
    }
  }

  return null
}
