export const removeQueryParamsFromLocation = (
  queryName: string | string[],
): void => {
  const url = new URL(window.location.href)

  if (Array.isArray(queryName)) {
    queryName.forEach((name) => url.searchParams.delete(name))
  } else {
    url.searchParams.delete(queryName)
  }

  window.history.pushState({ path: url.href }, '', url.href)
}
