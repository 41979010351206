import PostMessage from '@/dto/post-message'
import * as MessageType from '@/dto/post-message'

class PostMessageService {
  sendRedirectToMirrorFromLanding(): void {
    const message = new PostMessage(MessageType.REDIRECT_TO_MIRROR_FOR_LANDING)

    window.parent.postMessage(message, '*')
  }

  sendCloseModalEvent(payload?: Record<string, string | boolean> | null): void {
    const content = payload || {}
    const message = new PostMessage(MessageType.CLOSE_PAYMENT_MODAL, content)

    window.parent.postMessage(message, '*')
  }

  sendWidgetCreatedEvent(): void {
    window.parent.postMessage(new PostMessage(MessageType.PAYMENT_WIDGET_CREATED), '*')
  }

  sendFastPaymentCloseSuccessEvent(redirectUrl?: string): void {
    const messageContent = redirectUrl ? { redirectUrl } : null
    const message = new PostMessage(
      MessageType.CLOSE_PAYMENT_MODAL_SUCCESS_FAST_PAYMENT,
      messageContent,
    )

    window.parent.postMessage(message, '*')
  }

  sendShowSubscriptionModalEvent(): void {
    window.parent.postMessage(new PostMessage(MessageType.SHOW_SUBSCRIPTION_MODAL), '*')
  }

  sendCustomerSupportEvent(): void {
    window.parent.postMessage(new PostMessage(MessageType.CUSTOMER_SUPPORT), '*')
  }

  sendRedirectToUrlEvent(url: string): void {
    window.parent.postMessage(new PostMessage(MessageType.REDIRECT_TO_URL, { url }), '*')
  }
}

export default new PostMessageService()
