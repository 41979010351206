import ErrorTracking from '@socialtechnologies/error-tracking-lib'
import { UserWebTracker, normalizeObject } from '@socialtechnologies/tracking-lib'

import Api from '@/api/api'
import store from '@/store'
import type { CorefyEvent, Solid } from '@/types'
import {
  EventType,
  PayPalEventAction,
  ActionType,
  PageType,
  eventSubtypes,
  ITrackingParam,
  ITrackingEvent,
  ITrackingSource,
  TrackingObjectName,
  EventParamKeys,
  EventParamValues,
  AllActions,
} from '@/types/events'
import type { InteractionAction } from '@/types/solid'
import type { TokenIds } from '@/types/tracking'

const TRACK_SOURCE_DEBOUNCE_TIME = 200

class TrackingService {
  isInited = false

  userWebTracker = new UserWebTracker<EventType, AllActions, PageType, TrackingObjectName>()

  get userId(): number {
    return store.getters?.user?.idUser
  }

  get orderId(): ITrackingParam<number> {
    return {
      key: 'order_id',
      value: store.getters?.config?.info?.id_order,
    }
  }

  async init(): Promise<void> {
    try {
      await this.userWebTracker.init(this.getTrackingEndpoint(), TRACK_SOURCE_DEBOUNCE_TIME)
    } catch (e) {
      ErrorTracking.captureException(e)
    } finally {
      this.isInited = true
    }
  }

  private getTrackingEndpoint(): string {
    const host = window.location.hostname

    if (host === 'localhost') {
      return `https://${host}:${window.location.port}/platform/`
    }

    return Api.mirrorEndpoint
  }

  // eslint-disable-next-line max-params
  private getEventData(
    action: AllActions,
    subtype: string,
    params: ITrackingParam[] | null = null,
    isLanding = false,
  ): ITrackingEvent {
    return {
      type: isLanding ? EventType.PaymentsLanding : EventType.Payments,
      params,
      action,
      subtype,
    }
  }

  private getSourceData(object: ITrackingParam[] = []): ITrackingSource {
    return {
      pageType: PageType.Payment,
      pageUrl: window.location.href,
      targetUserId: this.userId,
      // @ts-expect-error
      object: {
        content: [...object, this.orderId],
      },
    }
  }

  // eslint-disable-next-line max-params
  async trackSource(
    eventType: string,
    eventData: ITrackingEvent,
    sourceData: ITrackingSource,
    noResultTracking = false,
  ): Promise<void> {
    if (!this.isInited) {
      setTimeout(() => this.trackSource(eventType, eventData, sourceData, noResultTracking), 100)

      return
    }

    const clientDate = new Date().toISOString()

    this.userWebTracker.trackEvent(eventType, eventData, sourceData, clientDate, noResultTracking)
  }

  // eslint-disable-next-line max-params
  async trackSourceWithoutDebounce(
    eventType: string,
    eventData: ITrackingEvent,
    sourceData: ITrackingSource,
    noResultTracking = false,
  ): Promise<void> {
    const clientDate = new Date().toISOString()

    await this.userWebTracker.trackEventWithoutDebounce(
      eventType,
      eventData,
      sourceData,
      clientDate,
      noResultTracking,
    )
  }

  solidSubmit(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Submit, eventSubtypes.Payments.Payment),
      this.getSourceData(),
    )
  }

  showPaymentForm(params: ITrackingParam[] | null = null): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, 'external_payment_form', params),
      this.getSourceData(),
    )
  }

  firstPaymentShow(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupOne),
      this.getSourceData(),
    )
  }

  firstPaymentClose(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupOne),
      this.getSourceData(),
    )
  }

  popupTwoSecondPageClick(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoSecondPagePay(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoSecondPageResign(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Resign, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoSecondPageShow(packageId: number, tokenIds: TokenIds): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(
        normalizeObject({
          package_id: packageId,
          tokens: tokenIds.allTokenIds.join(','),
          visible_tokens: tokenIds.visibleTokenIds.join(','),
        }),
      ),
    )
  }

  popupTwoThirdPagePay(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupTwoThirdPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoSecondPageBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoThirdPageBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupTwoThirdPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoThirdPageShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupTwoThirdPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupThirdPageBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupThird),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoFirstPageClick(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupTwoFirstPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoFirstPageShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupTwoFirstPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupTwoFirstPageBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupTwoFirstPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupThirdPageShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupThird),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async popupStatusTryAgainPageClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.TryAgain),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async popupStatusSuccessPageClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PaySuccess),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async popupTwoThirdPageClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupTwoThirdPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async popupTwoSecondPageClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupTwoSecondPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async popupTwoFirstPageClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupTwoFirstPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async tryAgainClick(packageId?: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.TryAgain),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async externalPaymentFormBack(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.ExternalPaymentForm),
      this.getSourceData(),
    )
  }

  corefyEvent(eventTypeParam: CorefyEvent): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(eventTypeParam, eventSubtypes.Payments.CorefyFormEvent),
      this.getSourceData(),
    )
  }

  solidInteraction(eventAction: InteractionAction, params: Record<string, string | boolean>): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(eventAction, eventSubtypes.Payments.SolidFormInteraction),
      this.getSourceData(
        normalizeObject({
          ...params,
        }),
      ),
    )
  }

  solidEvent<T>(eventAction: Solid.SolidEvent, params?: T): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(eventAction, eventSubtypes.Payments.SolidFormEvent),
      this.getSourceData(
        normalizeObject({
          ...params,
        }),
      ),
    )
  }

  subscriptionSubmit(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Submit, eventSubtypes.Payments.Subscription),
      this.getSourceData(),
    )
  }

  subscriptionSuccessClick(): Promise<void> {
    return this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.LetsExplore),
      this.getSourceData(),
    )
  }

  subscriptionFailClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.TryAgain),
      this.getSourceData(),
    )
  }

  paypalButtonEvent(action: PayPalEventAction): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(action, eventSubtypes.Payments.PaypalEvent),
      this.getSourceData(),
    )
  }

  paypalTabClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PaypalTab),
      this.getSourceData(),
    )
  }

  async contactSupportClick(packageId?: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.ContactSupport),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  toggleAutoTopUp(isOn: boolean): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(isOn ? ActionType.On : ActionType.Off, eventSubtypes.Payments.AutoTopup),
      this.getSourceData(),
    )
  }

  bigPackagesShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupBigPackage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesMorePackages(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.MorePackages, eventSubtypes.Payments.PopupBigPackage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesClick(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupBigPackage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesPay(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupBigPackage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async bigPackagesClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupBigPackage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesTwoPay(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupBigPackageTwo),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesTwoShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupBigPackageTwo),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesTwoResign(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Resign, eventSubtypes.Payments.PopupBigPackageTwo),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesTwoBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupBigPackageTwo),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async bigPackagesTwoClose(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupBigPackageTwo),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesThreeShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupBigPackageThree),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  bigPackagesThreeBack(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupBigPackageThree),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  payFastPayment(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupFastPayment),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  showFastPayment(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupFastPayment),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  showCoinsPaidConfirmationLoader(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.CryptoPaymentBlockedBanner, [
        { key: EventParamKeys.Reason, value: EventParamValues.WaitingForConfirmation },
      ]),
      this.getSourceData(),
    )
  }

  showCoinsPaidSessionLoader(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.CryptoPaymentBlockedBanner, [
        { key: EventParamKeys.Reason, value: EventParamValues.ActiveSession },
      ]),
      this.getSourceData(),
    )
  }

  showCryptoPaymentBlockedPopup(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.CryptoPaymentBlockedPopup),
      this.getSourceData(),
    )
  }

  morePackagesFastPayment(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.MorePackages, eventSubtypes.Payments.PopupFastPayment),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async closePaymentWidgetBigPackages(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupBigPackageFunnel),
      this.getSourceData(),
    )
  }

  async closeFastPaymentDesktop(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupFastPaymentFunnel),
      this.getSourceData(),
    )
  }

  async closeFastPaymentMobile(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupFastPayment),
      this.getSourceData(),
    )
  }

  async choosePackageFastPayment(packageId: number): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.ChoosePackage, eventSubtypes.Payments.PopupFastPayment),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  async closePaymentWidget(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupTwoFunnel),
      this.getSourceData(),
    )
  }

  closeCryptoPaymentBlockedPopup(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.CryptoPaymentBlockedPopup),
      this.getSourceData(),
    )
  }

  bankTabClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.BankTab),
      this.getSourceData(),
    )
  }

  cardTabClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.CardTab),
      this.getSourceData(),
    )
  }

  cryptoTabClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.CryptoTab),
      this.getSourceData(),
    )
  }

  cryptoDropdownClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.CryptoPaymentDropdown),
      this.getSourceData(),
    )
  }

  plaidConnectBankClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.ConnectBank),
      this.getSourceData(),
    )
  }

  plaidChooseBankClick(accountId: string, institutionName: string): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.ChooseBank),
      this.getSourceData(
        normalizeObject({
          account_id: accountId,
          institution_name: institutionName,
        }),
      ),
    )
  }

  cryptoWalletClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.CryptoWallet),
      this.getSourceData(),
    )
  }

  earlyCryptoWallerClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.EarlyCryptoWallet),
      this.getSourceData(),
    )
  }

  landingPaymentFormShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.ExternalPaymentForm, null, true),
      this.getSourceData(),
    )
  }

  landingPaymentFormClose(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.ExternalPaymentForm, null, true),
      this.getSourceData(),
    )
  }

  landingPaymentSubmit(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Submit, eventSubtypes.Payments.Payment, null, true),
      this.getSourceData(),
    )
  }

  landingSubscriptionPaymentSubmit(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Submit, eventSubtypes.Payments.Subscription, null, true),
      this.getSourceData(),
    )
  }

  landingProcessingShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PaymentProcessing, null, true),
      this.getSourceData(),
    )
  }

  landingSubscriptionProcessingShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.SubscriptionProcessing, null, true),
      this.getSourceData(),
    )
  }

  landingSuccessfulShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PaymentSuccessful, null, true),
      this.getSourceData(),
    )
  }

  landingSubscriptionSuccessfulShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.SubscriptionSuccessful, null, true),
      this.getSourceData(),
    )
  }

  landingSuccessfulClick(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PaymentSuccessful, null, true),
      this.getSourceData(),
    )
  }

  landingSubscriptionSuccessfulClick(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(
        ActionType.Click,
        eventSubtypes.Payments.SubscriptionSuccessful,
        null,
        true,
      ),
      this.getSourceData(),
    )
  }

  landingSuccessfulAutoRedirect(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(
        ActionType.AutoRedirect,
        eventSubtypes.Payments.PaymentSuccessful,
        null,
        true,
      ),
      this.getSourceData(),
    )
  }

  landingSubscriptionSuccessfulAutoRedirect(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(
        ActionType.AutoRedirect,
        eventSubtypes.Payments.SubscriptionSuccessful,
        null,
        true,
      ),
      this.getSourceData(),
    )
  }

  landingFailedShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PaymentFailed, null, true),
      this.getSourceData(),
    )
  }

  landingSubscriptionFailedShow(): void {
    this.trackSource(
      EventType.PaymentsLanding,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.SubscriptionFailed, null, true),
      this.getSourceData(),
    )
  }

  voltTabClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.VoltTab),
      this.getSourceData(),
    )
  }

  voltOpenBanking(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.VoltOpenBanking),
      this.getSourceData(),
    )
  }

  voltPaymentDropDown(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.VoltPaymentDropDown),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoFirstPageClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupSubscriptionTwoFirstPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoFirstPageClose(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupSubscriptionTwoFirstPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoSecondPageBack(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupSubscriptionTwoSecondPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoSecondPageClose(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupSubscriptionTwoSecondPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoSecondPageShow(tokenIds: TokenIds): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupSubscriptionTwoSecondPage),
      this.getSourceData(
        normalizeObject({
          tokens: tokenIds.allTokenIds.join(','),
          visible_tokens: tokenIds.visibleTokenIds.join(','),
        }),
      ),
    )
  }

  popupSubscriptionTwoSecondPagePay(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupSubscriptionTwoSecondPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoSecondPageClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupSubscriptionTwoSecondPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoThirdPageShow(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupSubscriptionTwoThirdPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoThirdPageClose(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupSubscriptionTwoThirdPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoThirdPageBack(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupSubscriptionTwoThirdPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoThirdPagePay(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupSubscriptionTwoThirdPage),
      this.getSourceData(),
    )
  }

  popupSubscriptionTwoFirstPageShow(packageId: number): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupSubscriptionTwoFirstPage),
      this.getSourceData(normalizeObject({ package_id: packageId })),
    )
  }

  popupSubscriptionTwoThirdPageResign(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Resign, eventSubtypes.Payments.PopupSubscriptionTwoThirdPage),
      this.getSourceData(),
    )
  }

  popupResignBack(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Back, eventSubtypes.Payments.PopupResign),
      this.getSourceData(),
    )
  }

  popupResignShow(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupResign),
      this.getSourceData(),
    )
  }

  popupResignPay(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Pay, eventSubtypes.Payments.PopupResign),
      this.getSourceData(),
    )
  }

  popupResignClose(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupResign),
      this.getSourceData(),
    )
  }

  tokenConfirmClick(tokenId: number, cardMask: string): void {
    this.trackSource(
      EventType.Payments,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.TokenConfirm),
      this.getSourceData(normalizeObject({ token: tokenId, card_mask: cardMask })),
    )
  }

  originalPricesShow(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Show, eventSubtypes.Payments.PopupOriginalPrices),
      this.getSourceData(),
    )
  }

  originalPricesClick(): void {
    this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Click, eventSubtypes.Payments.PopupOriginalPrices),
      this.getSourceData(),
    )
  }

  async originalPricesClose(): Promise<void> {
    await this.trackSource(
      EventType.Payment,
      this.getEventData(ActionType.Close, eventSubtypes.Payments.PopupOriginalPrices),
      this.getSourceData(),
    )
  }
}

export default new TrackingService()
