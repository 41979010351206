import * as amplitude from '@amplitude/analytics-browser'
import Cookies from 'universal-cookie'

import VendorConfig from '@/vendorConfig'

const cookies = new Cookies(null, { path: '/' })

export function addAmplitudeScript(id: number): void {
  const userId = id?.toString() || null
  const visitId = cookies.get('id_visit') || null

  amplitude.init(VendorConfig.amplitude.API_KEY, userId, {
    defaultTracking: {
      sessions: true,
      pageViews: false,
      formInteractions: true,
    },
  })

  amplitude.setDeviceId(visitId)

  const identifyEvent = new amplitude.Identify()

  identifyEvent.set(
    'device screen size',
    `${window.screen.width * window.devicePixelRatio}x${
      window.screen.height * window.devicePixelRatio
    }`,
  )
}
