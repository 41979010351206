import { DeclineReasons } from '@/constants'
import { ResignData } from '@/types/config/psp'

export type SuccessMessage = {
  content?: {
    descriptor: string
    idOrder: number
    creditsPremium: number
    paymentMethod: PaymentMethodType
  }
}

export type AutoResignData = {
  id_order: string
  resignData: ResignData
}

export type FailMessage = {
  content: {
    message: DeclineReasons.AllDeclineReasons
    code: number
    paymentMethod: PaymentMethodType
  }
  resign?: AutoResignData
}

export type FailMessageSecond = {
  message: DeclineReasons.AllDeclineReasons
  code: number
  paymentMethod: PaymentMethodType
}

export type SuccessMessageSecond = {
  descriptor: string
  idOrder: string
  creditsPremium: number
  paymentMethod: PaymentMethodType
}

export type Card = {
  id: number
  card: string
  type: CardType
  countFail: number
  countSuccess: number
  dateFreezeExpiration: string
  isFrozen: boolean
  forcedResign: boolean
  resignData?: ResignData
  elapsed?: number
}

export enum CardType {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Maestro = 'MAESTRO',
  DinersClub = 'DINERS CLUB',
  ChinaUnionpay = 'CHINA UNIONPAY',
  Discover = 'DISCOVER',
  JCB = 'JCB',
  Amex = 'AMEX',
  PayPal = 'PayPal',
  Sofort = 'Sofort',
  IDeal = 'iDeal',
  Corefy = 'Corefy',
  Plaid = 'Plaid',
  CoinsPaid = 'CoinsPaid',
  Volt = 'Volt',
}

export enum PaymentMethodType {
  Cryptocurrency = 'Cryptocurrency',
  BankTransfer = 'Bank Transfer',
  CreditCard = 'Credit Card',
}
