export const PAYMENT_SUCCESS = 'payment-success'
export const PAYMENT_FAIL = 'payment-fail'
export const REDIRECT = 'redirect'
export const CHANGE_AUTOCHARGE = 'change_autocharge'
export const CUSTOMER_SUPPORT = 'customer_support'
export const SHOW_SUBSCRIPTION_MODAL = 'show-subscription-modal'
export const CLOSE_PAYMENT_MODAL = 'close-payment-modal'
export const SET_IS_BIG_PACKAGE_FLOW = 'set-is-big-package-flow'
export const CLOSE_PAYMENT_MODAL_SUCCESS_FAST_PAYMENT = 'close-payment-modal-success-fast-payment'
export const PAYMENT_WIDGET_CREATED = 'payment-widget-created'
export const PAYMENT_WIDGET_RELOADED = 'payment-widget-reloaded'
export const REDIRECT_TO_MIRROR = 'redirectToMirror'
export const REDIRECT_TO_MIRROR_FOR_LANDING = 'redirect-to-mirror-for-landing'
export const REDIRECT_TO_URL = 'redirectToUrl'

export default class PostMessage {
  constructor(name, content = null) {
    this.name = name
    this.content = content
  }

  setName(name) {
    this.name = name
  }

  getName() {
    return this.name
  }

  setContent(content) {
    this.content = content
  }

  getContent() {
    return this.content
  }
}
