import PostMessage, { REDIRECT_TO_MIRROR } from '@/dto/post-message'
import { isMobile } from '@/helpers/isMobile'
import { isEthnic } from '@/helpers/mirror'
import MirrorsService from '@/services/MirrorsService'

class MirrorPageService {
  private backUrl: string

  get mirrorRoutePath(): string {
    if (this.backUrl && !this.backUrl.startsWith('http')) {
      return this.clearRoute(this.backUrl)
    }

    return isEthnic(MirrorsService.siteMirror) ? 'search' : 'news-feed'
  }

  get mirrorRoutePathSuccess(): string {
    const mirrorUrl = new URL(this.mirrorPagePath)

    mirrorUrl.searchParams.set('payment-success', 'true')

    return mirrorUrl.toString()
  }

  get mirrorPagePath(): string {
    const isHrefPassedAsBackUrl = this.backUrl && this.backUrl.startsWith('http')

    if (isHrefPassedAsBackUrl) {
      return this.backUrl
    }

    return `${MirrorsService.defaultOriginMirror}/${this.mirrorRoutePath}`
  }

  get originMirror(): string {
    return new URL(this.mirrorPagePath).origin
  }

  redirectToMirror(isPaymentSuccess: boolean, showSubscriptionPopUp = false): void {
    const mirrorUrl = new URL(this.mirrorPagePath)

    if (isPaymentSuccess) {
      mirrorUrl.searchParams.set('payment-success', 'true')
    }

    if (showSubscriptionPopUp) {
      mirrorUrl.searchParams.set('showSubscriptionPopUp', 'true')
    }

    const message = new PostMessage(REDIRECT_TO_MIRROR, {
      redirectUrl: mirrorUrl.toString(),
    })

    window.parent.postMessage(message, '*')
  }

  setBackUrl(backUrl: string): void {
    this.backUrl = backUrl
  }

  redirectToMirrorNotExclusivePost(isPaymentSuccess: boolean, showSubscriptionPopUp = false): void {
    const isExclusivePostUrl = /(dialogs|chat).*\/post/i

    if (isMobile() && isExclusivePostUrl.test(this.backUrl)) {
      const mirrorUrl = new URL(this.mirrorPagePath)
      const newPathname = mirrorUrl.pathname.split('/post')[0]

      this.backUrl = `${mirrorUrl.origin}${newPathname}${mirrorUrl.search}${mirrorUrl.hash}`
    }

    this.redirectToMirror(isPaymentSuccess, showSubscriptionPopUp)
  }

  private clearRoute(route: string): string {
    return route.startsWith('/') ? route.substring(1) : route
  }
}

export default new MirrorPageService()
