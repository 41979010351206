import type { GetterTree, MutationTree } from 'vuex'

import { SPLITS } from '@/constants/splits'
import { convertCreditsMessagingIntoGems } from '@/helpers/convertCreditsMessagingIntoGems'
import { isInSplit } from '@/helpers/split'
import { ConfigLayout } from '@/types/config'
import { CreditsPackage } from '@/types/config/packages'

import type { Common, RootState } from '../types'

const state: Common.State = {
  config: null,
  geoInfo: null,
  user: null,
  splits: null,
  template: null,
}

const mutations: MutationTree<Common.State> = {
  SET_CONFIG(state: Common.State, data: Common.Mutations['SET_CONFIG']): void {
    state.config = data
  },

  SET_GEO_INFO(state: Common.State, data: Common.Mutations['SET_GEO_INFO']): void {
    state.geoInfo = data
  },

  SET_USER(state: Common.State, data: Common.Mutations['SET_USER']): void {
    state.user = data
  },

  SET_SPLITS(state: Common.State, data: Common.Mutations['SET_SPLITS']): void {
    state.splits = data
  },

  SET_TEMPLATE(state: Common.State, data: Common.Mutations['SET_TEMPLATE']): void {
    state.template = data
  },

  SET_ORDER_ID(state: Common.State, data: Common.Mutations['SET_ORDER_ID']): void {
    state.config.info.id_order = data
  },

  SET_PACKAGES(state: Common.State, data: Common.Mutations['SET_PACKAGES']): void {
    state.config.packages = data
  },
}

const getters: GetterTree<Common.State, RootState> = {
  config(state: Common.State): Common.Getters['config'] {
    if (!state.config) {
      return null
    }

    return state.config
  },

  lastPurchasedPackage(state: Common.State): Common.Getters['lastPurchasedPackage'] {
    if (!state.config) {
      return null
    }

    return state.config.packages?.find(
      (pack) => pack.id === state.config.layout.lastPurchasedPackage,
    )
  },

  mostPopularPackage(state: Common.State): Common.Getters['mostPopularPackage'] {
    if (!state.config) {
      return null
    }

    return state.config.packages?.find((pack) => pack.isMostPopular)
  },

  actualPackage(state): Common.Getters['actualPackage'] {
    return state.config.layout.actualPackage
  },

  isUserInChatGemsSplit(state: Common.State): Common.Getters['isUserInChatGemsSplit'] {
    return isInSplit(SPLITS.CHAT_GEMS, state.splits)
  },

  isUserInFastPaymentUpsellSplit(
    state: Common.State,
  ): Common.Getters['isUserInFastPaymentUpsellSplit'] {
    return isInSplit(SPLITS.FAST_PAYMENT_UPSELL, state.splits)
  },

  isSubscriptionTemplate(state: Common.State): Common.Getters['isSubscriptionTemplate'] {
    return [
      ConfigLayout.LayoutTemplate.MOBILE_CREDITS_SUBSCRIPTION,
      ConfigLayout.LayoutTemplate.MOBILE_CREDITS_SUBSCRIPTION_2,
      ConfigLayout.LayoutTemplate.DESKTOP_CREDITS_SUBSCRIPTION_2,
      ConfigLayout.LayoutTemplate.FIRST_SUBSCRIPTION_LANDING_NEW,
      ConfigLayout.LayoutTemplate.DESKTOP_CREDITS_SUBSCRIPTION_1_POPUP,
      ConfigLayout.LayoutTemplate.SUBSCRIPTION_FROM_SECOND_PAYMENT,
    ].includes(state.template)
  },

  isFirstPaymentTemplate(state: Common.State): Common.Getters['isFirstPaymentTemplate'] {
    return [
      ConfigLayout.LayoutTemplate.ADAPTIVE_MOBILE,
      ConfigLayout.LayoutTemplate.DESKTOP_FIRST_POPUP,
      ConfigLayout.LayoutTemplate.DESKTOP_CREDITS_SUBSCRIPTION_1_POPUP,
      ConfigLayout.LayoutTemplate.FIRST_PAYMENT_LANDING,
      ConfigLayout.LayoutTemplate.MOBILE_CREDITS_SUBSCRIPTION,
      ConfigLayout.LayoutTemplate.FIRST_SUBSCRIPTION_LANDING_NEW,
    ].includes(state.template)
  },

  isWithTax(state: Common.State): Common.Getters['isWithTax'] {
    return state.config.layout.actualPackage.isTax
  },

  mirrorId(state: Common.State): Common.Getters['mirrorId'] {
    return state.config.common.mirrorInfo.abbreviation
  },

  geoInfo(state: Common.State): Common.Getters['geoInfo'] {
    return state.geoInfo
  },

  splits(state: Common.State): Common.Getters['splits'] {
    return state.splits
  },

  user(state: Common.State): Common.Getters['user'] {
    return state.user
  },

  userSegment(state: Common.State): Common.Getters['userSegment'] {
    return state.config.info.segment
  },

  template(state: Common.State): Common.Getters['template'] {
    return state.template
  },

  methodsList(state): Common.Getters['methodsList'] {
    return state.config.psp.methods
  },

  getGemsCountByPackageId:
        (state: Common.State) => (id: CreditsPackage['id']): CreditsPackage['creditsMessaging'] => {
          const creditsPackage = state.config.packages.find(
            (creditsPackage) => creditsPackage.id === id,
          )

          return convertCreditsMessagingIntoGems(creditsPackage?.creditsMessaging || 0)
        },
}

export default {
  state,
  mutations,
  getters,
}
