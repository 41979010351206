import type { MutationTree, GetterTree } from 'vuex'

import { DeclineReasons } from '@/constants'
import * as PaymentStatus from '@/store/types/paymentStatus'

import type { RootState } from '../types'

export const DEFAULT_STATUS: Readonly<PaymentStatus.Status> = {
  success: false,
  message: DeclineReasons.COREFY_DECLINE_REASONS.FALLBACK_MESSAGE,
  descriptor: '',
}

const initialState = (): PaymentStatus.State => ({
  status: {
    ...DEFAULT_STATUS,
  },
})

const mutations: MutationTree<PaymentStatus.State> = {
  SET_PAYMENT_STATUS(state, data: PaymentStatus.Mutations['SET_PAYMENT_STATUS']): void {
    state.status = {
      ...state.status,
      ...data,
    }
  },
  CLEAR_PAYMENT_STATUS(state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, initialState()) // eslint-disable-line no-param-reassign
  },
}

const getters: GetterTree<PaymentStatus.State, RootState> = {
  getPaymentStatus(state): PaymentStatus.Getters['getPaymentStatus'] {
    return state.status
  },
}

export default {
  state: initialState(),
  mutations,
  getters,
}
