import './defineGlobal'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Vue from 'vue'
import Meta from 'vue-meta'
import Vuex from 'vuex'

import store from '@/store'

import App from './App.vue'
import isMobilePlugin from './plugins/isMobilePlugin'
import router from './router'

Vue.use(Meta)
Vue.use(Vuex)
Vue.use(isMobilePlugin)

// eslint-disable-next-line no-new
new Vue({
  el: '#vue-mount',
  render: (h) => h(App),
  router,
  store,
})

dayjs.extend(utc)
