import ErrorTracking from '@socialtechnologies/error-tracking-lib'

import { getOrderFromUrl } from '@/helpers/getOrderFromUrl'

export const getHashFromUrl = (): string => {
  const { href } = window.location
  const url = new URL(href)

  const orderData = getOrderFromUrl()
  const hashFromUrl = url.searchParams.get('order') || ''
  const isEqual = hashFromUrl === orderData?.hash

  if (orderData?.hash && isEqual) {
    return orderData.hash
  }

  if (hashFromUrl) {
    ErrorTracking.captureMessage('Unable to fetch order data from new communication channel', {
      extra: {
        url: href,
        hashFromUrl,
        orderData,
        isEqual,
      },
    })

    return hashFromUrl
  }

  throw new Error('No order hash found')
}
